import React from 'react';
import query from 'querystringify';

// Components
import {
	MatsPagesStruct,
	MatPreviewModal,
	MatPreview,
	MainHeader,
	MainStruct,
	Container,
} from '../../components';
import { setMatName } from '../../redux/actionCreators/mat';
import {
	toggleSketchForFavourite,
	getMoreFavouriteSketches,
} from '../../redux/actionCreators/mats';
// Action creators
import { setActiveSketchPreview } from '../../redux/actionCreators/sketch';
import { toggle } from '../../redux/actionCreators/ui';
// Selectors
import getById from '../../redux/selectors/getById';
// Action types
import {
	GET_MORE_FAVOURITE_SKETCHES_$NETWORK,
	GET_FAVOURITE_SKETCHES_$NETWORK,
} from '../../redux/types/mats';
// Static
import browserHistory from '../../static/global';

// Utils
import { getMatFavouriteStatus, getMatStatus } from '../../utils';
import addQuery from '../../utils/addQuery';

// ----------------

export default function MyFavouriteSketches(props) {
	const { history } = browserHistory;
	const q = query.parse(history.location.search);

	return (
		<>
			<MainStruct
				header={
					<Container
						mapDispatch={{ toggle }}
						mapState={(state) => ({
							favouriteQuantity: state.mats.favouriteSketchesQty,
							sidebarOpen: state.ui.active.sidebar,
						})}
						render={MainHeader}
					/>
				}
			>
				<Container
					mapDispatch={{
						toggleSketchForFavourite,
						getMoreFavouriteSketches,
						setActiveSketchPreview,
						toggle,
					}}
					mapState={(state) => ({
						getMoreNetworkProcess: state.network[GET_MORE_FAVOURITE_SKETCHES_$NETWORK],
						myFavouriteSketches: state.mats.favouriteSketches,
						showLoadMoreButton: state.mats.showLoadMoreButton,
						getNetworkProcess: state.network[GET_FAVOURITE_SKETCHES_$NETWORK],
					})}
					render={(cProps) => (
						<MatsPagesStruct
							loadMoreNetworkProcess={cProps.getMoreNetworkProcess}
							getNetworkProcess={cProps.getNetworkProcess}
							showLoadMore={cProps.showLoadMoreButton}
							onLoadMore={() => {
								addQuery({ page: cProps.q.page ? +cProps.q.page + 1 : 2 });
								cProps.getMoreFavouriteSketches();
							}}
							titleIcon="favorite_border"
							title="My Favorite Sketches"
							mats={cProps.myFavouriteSketches}
							card={({
								sketchRequestID,
								productGroupID,
								attributeName,
								companyName,
								requestDate,
								designName,
								sketchRef,
								sketchURL,
								scanURL,
								imageName,
								viewMode,
								...cardProps
							}) => (
								<MatPreview
									withFavouriteIcon
									onFavouriteClick={(id) =>
										cProps.toggleSketchForFavourite(id, 'delete', 'favouriteSketches')
									}
									withHoverEffect
									companyName={companyName}
									imageName={imageName || undefined}
									favourite={getMatFavouriteStatus(cardProps)}
									maxWidth="auto"
									viewMode={viewMode}
									onClick={() => {
										cProps.setActiveSketchPreview(
											sketchRequestID,
											'sketch',
											'favouriteSketches'
										);
										cProps.toggle('matPreviewModal');
									}}
									sketchRef={sketchRef}
									status={getMatStatus(cardProps)}
									image={sketchURL || scanURL}
									size={attributeName}
									name={designName}
									date={requestDate}
									type={productGroupID || undefined}
									id={sketchRequestID}
								/>
							)}
						/>
					)}
					extra={{ q }}
				/>
			</MainStruct>

			<Container
				mapDispatch={{
					toggleSketchForFavourite,
					setMatName,
					toggle,
				}}
				mapState={(state) => ({
					matDetails:
						(state.sketch.activeSketchPreview &&
							getById(
								state.mats.favouriteSketches,
								'sketchRequestID',
								state.sketch.activeSketchPreview.id
							)) ||
						{},
					modalOpen: state.ui.active.matPreviewModal,
				})}
				render={({
					matDetails: {
						sketchRequestID,
						productGroupID,
						attributeName,
						companyName,
						requestDate,
						designName,
						imageName,
						sketchRef,
						sketchURL,
						scanURL,
						...matProps
					},
					...cProps
				}) => (
					<MatPreviewModal
						onVariation={() => {
							cProps.setMatName(designName);
							cProps.toggle('matPreviewModal');
							cProps.toggle('variationSketchModal');
						}}
						onApprove={() => {
							cProps.toggle('matPreviewModal');
							cProps.toggle('customerApprovalModal');
						}}
						onPreview={() => {
							cProps.toggle('matPreviewModal');
							browserHistory.history.push(`/preview/${sketchRequestID}`);
						}}
						onClose={() => cProps.toggle('matPreviewModal')}
						open={cProps.modalOpen || false}
						data={{
							withFavouriteIcon: true,
							onFavouriteClick: (id) => {
								cProps.toggle('matPreviewModal');
								cProps.toggleSketchForFavourite(id, 'delete', 'favouriteSketches');
							},
							companyName: companyName,
							imageName: imageName,
							sketchRef: sketchRef,
							favourite: getMatFavouriteStatus(matProps),
							status: getMatStatus(matProps),
							image: sketchURL || scanURL,
							type: productGroupID || undefined,
							size: attributeName,
							name: designName,
							date: requestDate,
							id: sketchRequestID,
						}}
					/>
				)}
			/>
		</>
	);
}
