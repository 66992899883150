import types from 'prop-types';
import React from 'react';

// Components

import InputField from '../../../../components/ui/InputField';
import Button from '../../../../components/ui/Button';
import ErrorField from '../../../../components/block/ErrorField';
import SvgIcon from '../../../../components/block/SvgIcon';

// Assets

import { letterSendIcon } from '../../../../assets/icons';

// Styles

import './styles.scss';

// ----------------

// Type of props

ResetPasswordForm.propTypes = {
	networkProcess: types.bool,
	extraState: types.object.isRequired,
	onSubmit: types.func.isRequired,
	fields: types.object.isRequired,
};

// Default value for props

ResetPasswordForm.defaultProps = {
	onSubmit: () => null,
};

// ----------------

export default function ResetPasswordForm(props) {
	const { networkProcess, extraState, onSubmit, fields } = props;

	return (
		<form className="reset-password-form" onSubmit={onSubmit}>
			{!extraState.isRequestSend ? (
				<>
					<p className="reset-password-form__description">
						We will send a reset link to your email address
					</p>
					<fieldset className="reset-password-form__fields">
						<InputField
							{...fields.username}
							placeholder="Username"
							theme="secondaryDark"
							fluid
						/>
						{extraState.notValidData && (
							<div className="sign-in-form__error">
								<ErrorField fluid error="Username does not exist." />
							</div>
						)}
					</fieldset>
				</>
			) : (
				<div className="reset-password-form__success-block">
					<h2 className="success-block__title">New Password Request</h2>
					<div className="success-block__icon-wrapper">
						<SvgIcon size="auto" url={letterSendIcon} />
					</div>
					<h2 className="success-block__title">Check your inbox</h2>
					<span className="success-block__subtitle">
						You must receive a reset link within 10 minutes
					</span>
				</div>
			)}
			<div className="reset-password-form__buttons">
				<Button
					borderWeight="bold"
					isLoading={networkProcess}
					loaderColor="white"
					onSubmit={onSubmit}
					fluid
					theme="onPrimary"
					type="submit"
					size="lg"
					id="reset-password-form-submit-button"
				>
					{extraState.isRequestSend ? 'Resend Link' : 'Send Link'}
				</Button>
				<Button
					borderWeight="bold"
					reverseType
					fluid
					theme="onPrimary"
					link="/signin"
					size="lg"
					id="reset-password-form-back-button"
				>
					Back to Sign In
				</Button>
			</div>
		</form>
	);
}
