import Cropper from 'cropperjs';
import types from 'prop-types';
import React from 'react';

// Components

import FontIcon from '../../../../components/block/FontIcon';
import Button from '../../../../components/ui/Button';
import Modal from '../../../../components/block/Modal';

// Styles

import './../../../../../node_modules/cropperjs/src/css/cropper.scss';
import './styles.scss';

// ----------------

export default class ImageCropModal extends React.Component {
	// Type of props

	static propTypes = {
		onClose: types.func.isRequired,
		onSave: types.func.isRequired,
		object: types.object.isRequired,
	};

	// -------- Constructor --------

	constructor(props) {
		super(props);

		this.state = {
			historyPosition: -1,
			history: [],
		};

		this.cropper = null;

		// Binds

		this.handleCrop = this.handleCrop.bind(this);
		this.handleUndo = this.handleUndo.bind(this);
		this.handleRedo = this.handleRedo.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.handleScale = this.handleScale.bind(this);
		this.handleZoom = this.handleZoom.bind(this);
		this.pushToHistory = this.pushToHistory.bind(this);

		// Refs

		this.cropRef = React.createRef();
	}

	// -------- Life cycle --------

	componentDidMount() {
		this.config();
	}

	// -------- Utils --------

	/**
	 * @name config
	 */

	config() {
		// Load image

		const {
			object: { url },
		} = this.props;

		const image = new Image();

		image.src = url;
		image.onload = () => {
			this.cropper = new Cropper(image, {
				initialAspectRatio: image.width / image.height,
				autoCropArea: 1,
				background: false,
				viewMode: 1,
				center: true,
				modal: false,
			});
		};

		image.style.width = '100%';
		this.cropRef.current && this.cropRef.current.appendChild(image);

		this.pushToHistory(url);
	}

	// -------- Handlers --------

	handleCrop(saveCrop = false) {
		const cr = this.cropper;
		const widthRatio = cr.getCropBoxData().width / cr.initialCropBoxData.width;
		const heightRatio = cr.getCropBoxData().height / cr.initialCropBoxData.height;
		const imageDataURL = cr.getCroppedCanvas().toDataURL();

		if (!!saveCrop) {
			this.pushToHistory(imageDataURL);

			cr.destroy();
			cr.replace(imageDataURL, true);
		}

		return {
			imageDataURL,
			widthRatio,
			heightRatio,
		};
	}

	handleReset() {
		this.cropper.reset();
	}

	handleScale(direction = 'x') {
		if (direction === 'x') {
			this.cropper.scaleX(this.cropper.getData().scaleX === 1 ? -1 : 1);
		} else this.cropper.scaleY(this.cropper.getData().scaleY === 1 ? -1 : 1);
	}

	handleZoom(value) {
		this.cropper.zoom(value);
	}

	handleUndo() {
		if (this.state.historyPosition > 0) {
			const imageData = this.state.history[this.state.historyPosition - 1];

			this.cropper.destroy();
			this.cropper.replace(imageData, true);

			this.setState((prevState) => ({
				historyPosition: prevState.historyPosition - 1,
			}));
		}
	}

	handleRedo() {
		if (this.state.historyPosition + 1 !== this.state.history.length) {
			const imageData = this.state.history[this.state.historyPosition + 1];

			this.cropper.destroy();
			this.cropper.replace(imageData, true);

			this.setState((prevState) => ({
				historyPosition: prevState.historyPosition + 1,
			}));
		}
	}

	pushToHistory(imageData) {
		this.setState((prevState) => ({
			historyPosition: prevState.historyPosition + 1,
			history: [...prevState.history.splice(0, prevState.historyPosition + 1), imageData],
		}));
	}

	// -------- Render --------

	render() {
		const { onClose, onSave } = this.props;

		return (
			<Modal
				withoutPadding
				bodyAccent
				bodyHeight="full"
				maxWidth="maxFull"
				onClose={onClose}
				title="Crop image"
				open
			>
				<div className="crop-image-modal">
					{/* Header */}

					<div className="crop-image-modal__header">
						<div className="crop-image-modal__header-actions-wrapper">
							{/* Zoom In/Zoom Out/Reset */}

							<div
								onClick={() => this.handleZoom(0.1)}
								className="crop-image-modal__header-action"
							>
								<FontIcon icon="zoom_in" size="xl" />
							</div>
							<div
								onClick={() => this.handleZoom(-0.1)}
								className="crop-image-modal__header-action"
							>
								<FontIcon icon="zoom_out" size="xl" />
							</div>
							<div onClick={this.handleReset} className="crop-image-modal__header-action">
								<FontIcon icon="cached" size="xl" />
							</div>

							<div className="crop-image-modal__header-line" />

							{/* Swap Horizontal/Swap Vertical */}

							<div
								onClick={() => this.handleScale('x')}
								className="crop-image-modal__header-action"
							>
								<FontIcon icon="swap_horiz" size="xl" />
							</div>
							<div
								onClick={() => this.handleScale('y')}
								className="crop-image-modal__header-action"
							>
								<FontIcon icon="swap_vert" size="xl" />
							</div>

							<div className="crop-image-modal__header-line" />

							{/* Crop/Undo/Redo */}

							<div
								onClick={() => this.handleCrop(true)}
								className="crop-image-modal__header-action"
							>
								<FontIcon icon="crop" size="xl" />
							</div>
							<div onClick={this.handleUndo} className="crop-image-modal__header-action">
								<FontIcon icon="undo" size="xl" />
							</div>
							<div onClick={this.handleRedo} className="crop-image-modal__header-action">
								<FontIcon icon="redo" size="xl" />
							</div>
						</div>
					</div>

					{/* Body */}

					<div className="crop-image-modal__body">
						<div ref={this.cropRef} id="crop-wrapper" />
					</div>

					{/* Buttons */}

					<div className="crop-image-modal__buttons">
						<div className="modal__two-buttons-grid crop-image-modal__buttons-grid">
							<Button
								reverseType
								onClick={onClose}
								fluid
								id="crop-image-modal-cancel-button"
							>
								Cancel
							</Button>
							<Button
								fluid
								onClick={() => {
									const { imageDataURL, widthRatio, heightRatio } = this.handleCrop();
									return onSave(imageDataURL, widthRatio, heightRatio);
								}}
								id="crop-image-modal-submit-button"
							>
								Save
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}
