import classNames from 'classnames';
import query from 'querystringify';
import types from 'prop-types';
import React from 'react';

// Components

import SearchInput from '../../../../components/ui/SearchInput';
import IconButton from '../../../../components/ui/IconButton';
import Form from '../../../../modules/form-x';

// Utils

import addQuery from '../../../../utils/addQuery';

// Static

import browserHistory from '../../../../static/global';

// Styles

import './styles.scss';

// ----------------

// Type of props

SearchPageHeader.propTypes = {
	isFiltersButtonShow: types.bool.isRequired,
	defaultSearchValue: types.string,
	asideOpen: types.bool.isRequired,
	onSearch: types.func.isRequired,
	toggle: types.func.isRequired,
};

SearchPageHeader.defaultProps = {
	asideOpen: false,
};

// ----------------

export default function SearchPageHeader(props) {
	const { isFiltersButtonShow, defaultSearchValue, asideOpen, onSearch, toggle } = props;
	const { history } = browserHistory;
	const q = query.parse(history.location.search);

	// Modify styles

	const modifySettingsButton = classNames({
		' search-page-struct__settings-btn--active': asideOpen,
	});

	// Render
	return (
		<header className="search-page-header">
			<Form
				onSubmit={(data) => {
					if (data.search) {
						addQuery(data);
						onSearch();
					} else {
						addQuery({}, ['search']);
						onSearch();
					}
				}}
				fields={{ search: { value: defaultSearchValue || '' } }}
				render={(formProps) => (
					<form style={{ width: '100%' }} onSubmit={formProps.onSubmit}>
						<SearchInput
							placeholder="Search Sketches..."
							onIconClick={formProps.onSubmit}
							onChange={formProps.fields.search.onChange}
							onClear={() => {
								addQuery({}, ['search']);
								formProps.onClear('search');
							}}
							value={formProps.fields.search.value}
							name={formProps.fields.search.name}
						/>
					</form>
				)}
				data={{ search: q.search || '' }}
			/>
			{isFiltersButtonShow && (
				<div className={`search-page-header__settings-btn${modifySettingsButton}`}>
					<IconButton
						handleClick={() => toggle('searchAside')}
						buttonSize="stretch"
						iconType="fontIcon"
						iconSize="xl"
						icon="tune"
						key={1}
						id="search-filters-refine-button"
					/>
				</div>
			)}
		</header>
	);
}
