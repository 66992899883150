import createReduxStore from './redux';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';
import React from 'react';
import './lib/sentry';

// Static

import userEndpoints from './static/endpoints/user';
import { cookie } from './static/constants';
import browserHistory from './static/global';

// Utils

import fetch from './utils/fetch';

// ----------------

/**
 * Preparation for render.
 * Allows you to perform any logic before the first render.
 *
 * @returns {object|undefined} PreloadedState for the redux or undefined.
 */

async function preparationForRender() {
	const chunk = await import('./customers/index');
	const customer = chunk.default;

	// Saving pages setting to the global object

	browserHistory.pages = customer.pages;

	// Creating css variables for theme

	const theme = customer.theme;
	const root = document.documentElement;
	const keys = Object.keys(theme);

	keys.forEach((key) => {
		root.style.setProperty(`--${key}`, theme[key]);
	});

	// Create preloaded state

	const token = Cookies.get(cookie.TOKEN);

	if (token) {
		let preloadedState;
		let userData;

		try {
			userData = await fetch(userEndpoints.USER, {
				method: 'POST',
				body: {
					url:
						process.env.REACT_APP_LOCATIONS_REQUEST_URL ||
						'https://www.cintasmats.com',
				},
			});

			preloadedState = {
				user: {
					userSettings: {
						dashboardFilters: {},
					},
					userData: {},
				},
			};

			preloadedState.user.userData = userData.Result.Data;
		} catch (err) {
			if (err.code === 401) {
				Cookies.remove(cookie.TOKEN);

				browserHistory.history.push('/signin');
			}
		}

		return preloadedState;
	}

	return undefined;
}

// Then, we can create the store and do first render

preparationForRender().then(async (preloadedState) => {
	const store = createReduxStore(preloadedState);
	window.store = store; // For test

	browserHistory.store = store;

	const Root = require('./Root').default;

	ReactDOM.render(
		<Provider store={store}>
			<Root />
		</Provider>,
		document.getElementById('render')
	);
});

// Set listener on route history change

browserHistory.history.listen(() => {
	const elements = document.getElementsByClassName('scroll');

	if (!elements.length) return;

	let i = 0;

	while (i < elements.length) {
		elements[i].scrollTop = 0;
		i++;
	}
});
