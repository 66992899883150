import types from 'prop-types';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';

// Components

import Size from '../../../components/block/Size';

// Styles

import './styles.scss';

// ----------------

// Type of props

SizeList.propTypes = {
	onAddCustomSizeClick: types.func.isRequired,
	onSizeClick: types.func.isRequired,
	selectedId: types.number,
	list: types.array,
};

// ----------------

const customRegNumber = (before, after) => {
	return after
		? `^((?!0)\\d{0,${before}}|0|\\.\\d{1,${after}})($|\\.$|\\.\\d{1,${after}}$)`
		: `^[0-9]{0,${before}}$`;
};

export const getInputValue = (value) => {
	if (value == 0) {
		return value;
	}

	if (value) {
		return value;
	}

	return '';
};

export default function SizeList(props) {
	const { onSizeClick, selectedId, list, onAddCustomSizeClick } = props;

	// const splitSize = selectedId.split('x');

	const [customSize, setCustomSize] = useState({ x: '', y: '' });

	const [validX, setValidX] = useState(false);
	const [validY, setValidY] = useState(false);

	useEffect(() => {
		const isGetCustom = list.find(({ isCustom }) => isCustom);
		if (isGetCustom) {
			const separateSize = isGetCustom.value.split('x');
			setCustomSize({ x: separateSize[0], y: separateSize[1] });
		}
	}, []);

	const { lengthMax, lengthMin, widthMax, widthMin } = list[0].validation;

	const xIsValid = customSize.x >= widthMin && customSize.x <= widthMax;
	const yIsValid = customSize.y >= lengthMin && customSize.y <= lengthMax;

	return (
		<div className="size-list">
			<div className="size-list__grid">
				{list.map(({ id, value, isCustom }) =>
					!isCustom ? (
						<div className="size-list__cell" key={id}>
							<Size
								{...(selectedId === id
									? { active: true }
									: {
											onClick: () => {
												setCustomSize({ x: '', y: '' });
												onSizeClick(id);
											},
									  })}
							>
								{value}
							</Size>
						</div>
					) : null
				)}
			</div>
			<div className="size-list__custom">
				<p className="text-small tooltip-default-view__title size-list__title">
					Custom Mat Size
				</p>
				<div className="size-list__custom_wrapper">
					<div>
						<input
							className={cn('size-list__custom_input', {
								'size-list__custom_input_error': validX,
							})}
							placeholder={`${widthMin}-${widthMax}`}
							type="text"
							value={getInputValue(customSize.x)}
							onChange={(e) => {
								const { value } = e.target;
								if (value.match(customRegNumber(String(widthMax).length))) {
									setValidX(false);
									setCustomSize({ ...customSize, x: value });
								}
							}}
						/>
						{validX && (
							<p className="size-list__custom_validText">enter a valid number</p>
						)}
					</div>
					<span style={{ marginTop: '2px' }}>&#215;</span>
					<div>
						<input
							className={cn('size-list__custom_input', {
								'size-list__custom_input_error': validY,
							})}
							type="text"
							placeholder={`${lengthMin}-${lengthMax}`}
							value={getInputValue(customSize.y)}
							onChange={(e) => {
								const { value } = e.target;
								if (value.match(customRegNumber(String(lengthMax).length))) {
									setValidY(false);
									setCustomSize({ ...customSize, y: value });
								}
							}}
						/>
						{validY && (
							<p className="size-list__custom_validText">enter a valid number</p>
						)}
					</div>
					<button
						disabled={!customSize.x || !customSize.y}
						onClick={() => {
							if (!xIsValid) {
								setValidX(true);
							}
							if (!yIsValid) {
								setValidY(true);
							}

							if (xIsValid && yIsValid) {
								onAddCustomSizeClick({
									value: `${customSize.x}x${customSize.y}`,
									isCustom: true,
									id: list.filter((size) => !size.isCustom).length + 1,
								});
								onSizeClick(list.filter((size) => !size.isCustom).length + 1);
							}
						}}
						className="size-list__custom_button"
					>
						add
					</button>
				</div>
			</div>
		</div>
	);
}
