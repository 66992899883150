import createRouter, { TopWrapper } from '../modules/react-router-x';
import Cookies from 'js-cookie';
import query from 'querystringify';
import React from 'react';

// Components

import {
	CustomerApprovalModal,
	NewSketchRequestModal,
	SystemNotification,
	FullScreenLoader,
	Container,
} from '../components';

// Static

import { matType, cookie } from '../static/constants';
import browserHistory from '../static/global';

// Selectors

import { getUserData } from '../redux/selectors/user';

// Top routes

import MyFavouriteSketches from './top/MyFavouriteSketches';
import MyLocationSketches from './top/MyLocationSketches';
import MySketches from './top/MySketches';
import MatPreview from './top/MatPreview';
import Dashboard from './top/Dashboard';
import Marketing from './top/Marketing';
import MyDrafts from './top/MyDrafts';
import Search from './top/Search';
import Auth from './top/Auth';
import Mat from './top/Mat';

// Sub routes

import MarketingVideos from './sub/MarketingVideos';
import MarketingFlyers from './sub/MarketingFlyers';
import ResetPassword from './sub/ResetPassword';
import Signup from './sub/Signup';
import Signin from './sub/Signin';

// Action creators

import { getMarketingMaterials } from '../redux/actionCreators/marketing';
import { getLocations$network } from '../redux/actionCreators/meta';
import { getUserSettings } from '../redux/actionCreators/user';
import { toggle } from '../redux/actionCreators/ui';
import {
	getFavouriteSketchesQuantity$network,
	getFavouriteSketches$network,
	getLocationSketches$network,
	getSearchSketches$network,
	getSketches$network,
	getDrafts$network,
} from '../redux/actionCreators/mats';
import {
	getSketchDataById$network,
	updateSketchDocument,
	setSketchVariation,
	setSketchDataById,
	restoreBranch as restoreSketchBranch,
} from '../redux/actionCreators/sketch';
import {
	generateSketchPdf,
	getDraft$network,
	restoreBranch,
	matSnapshots,
	getLYLImages,
	toggleModal,
	restoreMat,
	setMatName,
	getColors,
	getSizes,
	matReady,
} from '../redux/actionCreators/mat';
import { startLoading, endLoading } from '../redux/actionCreators/system';

// Utils

import addQuery from '../utils/addQuery';

// ----------------

const main = {
	notFoundRedirect: '/dashboard',
	name: 'main',
	routes: [
		{
			redirect: '/dashboard',
			path: '/',
		},
		{
			component: () => <Dashboard setting={browserHistory.pages.dashboard} />,
			title: 'Dashboard',
			path: '/dashboard',
			onMount: async () => {
				const { dispatch, getState } = browserHistory.store;
				const { CustomerID, UserID } = await getState().user.userData;

				dispatch(
					getLocationSketches$network({
						recordCount: 6,
						locationID: CustomerID || 6110,
						placedByID: UserID,
					})
				);

				dispatch(
					getSketches$network({
						recordCount: 6,
						placedByID: UserID,
					})
				);

				dispatch(
					getDrafts$network({
						recordCount: 6,
						pageNumber: 0,
						sortExpr: 'date',
						sortDir: 'desc',
					})
				);
			},
		},
		{
			component: () => <MyDrafts />,
			title: 'My Drafts',
			path: '/my-drafts',
			onMount: () => {
				const { history, store } = browserHistory;
				const q = query.parse(history.location.search);

				Object.keys(q).forEach((item) => addQuery({}, [item]));

				store.dispatch(
					getDrafts$network({
						recordCount: 12,
						pageNumber: 0,
						sortExpr: 'date',
						sortDir: 'desc',
					})
				);
			},
		},
		{
			component: () => <MySketches />,
			title: 'My Sketches',
			path: '/my-sketches',
			onMount: async () => {
				const { history, store } = browserHistory;
				const { UserID } = await getUserData(store.getState());
				const q = query.parse(history.location.search);

				Object.keys(q).forEach((item) => addQuery({}, [item]));

				store.dispatch(getSketches$network({ placedByID: UserID, searchOrder: 'desc' }));
			},
		},
		{
			component: () => <MyLocationSketches />,
			title: 'My Location Sketches',
			path: '/my-location-sketches',
			onMount: async () => {
				const { history, store } = browserHistory;
				const { CustomerID, UserID } = await getUserData(store.getState());
				const q = query.parse(history.location.search);

				Object.keys(q).forEach((item) => addQuery({}, [item]));

				store.dispatch(
					getLocationSketches$network({
						locationID: CustomerID || 6110,
						placedByID: UserID,
					})
				);
			},
		},
		{
			component: () => <MyFavouriteSketches />,
			title: 'My Favorite Sketches',
			path: '/my-favourite-sketches',
			onMount: () => {
				const { history, store } = browserHistory;
				const q = query.parse(history.location.search);

				Object.keys(q).forEach((item) => addQuery({}, [item]));

				store.dispatch(
					getFavouriteSketches$network({
						recordCount: 12,
						pageNumber: 1,
					})
				);
			},
		},
		{
			component: () => <Marketing subRouter={routers.marketingSub} />,
			notExact: true,
			path: '/marketing',
			onMount: () => {
				const { dispatch } = browserHistory.store;

				dispatch(getMarketingMaterials());
			},
		},
		{
			component: () => <Search />,
			title: 'Search',
			path: '/search',
			onMount: () => {
				const {
					history,
					store: { dispatch },
				} = browserHistory;
				const q = query.parse(history.location.search);

				Object.keys(q).forEach((item) => addQuery({}, [item]));

				dispatch(getSearchSketches$network());

				// Get sizes for size filters

				dispatch(getSizes());
			},
			onUnMount: () => {
				const { dispatch, getState } = browserHistory.store;

				if (getState().ui.active.searchAside) {
					dispatch(toggle('searchAside'));
				}
			},
		},
		{
			component: (props) => <Mat setting={browserHistory.pages.mat} {...props} />,
			title: 'Draft',
			path: '/draft/:id?',
			onMount: async (history) => {
				const { store } = browserHistory;
				const {
					match: { params },
				} = history;

				store.dispatch(startLoading(false));
				// Get colors for draft | Get sizes for draft
				if (!store.getState().mat.matSizes.length) {
					await store.dispatch(getSizes());
				}
				await Promise.all([store.dispatch(getColors())]);

				// Restore draft
				// objects: {
				// 	1: {
				// 		angle: 0,
				// 		bold: false,
				// 		height: 223.16666666666666,
				// 		id: 1,
				// 		italic: false,
				// 		left: 50,
				// 		objectType: "image",
				// 		top: 159.5,
				// 		url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=',
				// 		visible: true,
				// 		width: 284,
				// 		zIndex: 1
				// 	}
				// },
				// bgImage: null

				function toBlobUrl(url, callback) {
					var xhr = new XMLHttpRequest();
					xhr.onload = function () {
						var reader = new FileReader();
						reader.onloadend = function () {
							callback(reader.result);
						};
						reader.readAsDataURL(xhr.response);
					};
					xhr.open('GET', url);
					xhr.responseType = 'blob';
					xhr.send();
				}

				const draftStatus = localStorage.getItem('draftStatus');

				if (params.id) {
					const draftData = await store.dispatch(getDraft$network(params.id));
					const jsonData = JSON.parse(draftData.result.data.jsonData);

					if (!jsonData.matType) {
						Object.keys(matType).forEach((matTypeKey) => {
							if (draftData.result.data.productGroupID === matType[matTypeKey].code) {
								jsonData.matType = matTypeKey;
							}
						});
					}

					if (jsonData.bgColor === '0') {
						jsonData.bgColor = undefined;
					}

					if (!store.getState().mat.matSize && jsonData.matInfo.variationOf) {
						store.dispatch(
							setSketchVariation({
								name: jsonData.matInfo.sketchName,
								id: jsonData.matInfo.variationOf,
								notSave: true,
							})
						);
					}

					if (draftData.result.data) {
						if (jsonData.bgImage && !draftStatus) {
							toBlobUrl(jsonData.bgImage, function (myBase64) {
								store.dispatch(
									restoreMat({
										...jsonData,
										objects: {
											1: {
												angle: 0,
												bold: false,
												id: 1,
												italic: false,
												objectType: 'image',
												url: myBase64,
												visible: true,
												zIndex: 1,
											},
										},
										bgImage: '',
										isVariationMode: true,
									})
								);

								store.dispatch(matSnapshots(true));
								store.dispatch(matReady());
							});
						} else {
							store.dispatch(restoreMat(jsonData));

							store.dispatch(matSnapshots(true));
							if (draftData.result.data.attributeName) {
								store.dispatch(
									restoreMat({
										...jsonData,
										matSize: draftData.result.data.attributeName,
									})
								);
								store.dispatch(getSizes(draftData.result.data.attributeName));
							}
						}
					} else {
						browserHistory.history.push('/dashboard');
					}
				} else {
					if (store.getState().mat.matType === matType.LYL.name) {
						store.dispatch(getLYLImages());

						setTimeout(() => {
							store.dispatch(toggleModal('LYLImage'));
						}, 2200);
					} else {
						setTimeout(() => {
							store.dispatch(toggleModal('image'));
						}, 2600);
					}
				}

				// Render mat

				store.dispatch(matReady());

				// Hide loader

				setTimeout(() => {
					store.dispatch(endLoading());
				}, 2000);
			},
			onUnMount: () => {
				const { dispatch } = browserHistory.store;

				dispatch(restoreBranch());
			},
		},
		{
			component: () => <MatPreview />,
			title: 'Mat preview',
			path: '/preview/:id?',
			onMount: async (history) => {
				const { dispatch, getState } = browserHistory.store;
				const {
					match: { params },
				} = history;

				dispatch(startLoading(false));

				// Restore sketch preview data
				if (params.id) {
					const sketchData = await dispatch(getSketchDataById$network(params.id));

					if (sketchData.result.data) {
						dispatch(setSketchDataById(sketchData.result.data));

						if (getState().sketch.sketchData) {
							setTimeout(() => {
								dispatch(endLoading());
							}, 2000);
						}
					} else {
						browserHistory.history.push('/dashboard');
					}
				}
			},
			onUnMount: () => {
				const { dispatch } = browserHistory.store;

				dispatch(restoreSketchBranch());
			},
		},
	],
	onMount: () => {
		const { dispatch } = browserHistory.store;

		dispatch(getFavouriteSketchesQuantity$network());
		dispatch(getUserSettings());
	},
};

const marketingSub = {
	notFoundRedirect: '/marketing',
	name: 'marketingSub',
	routes: [
		{
			component: MarketingVideos,
			title: 'Marketing Videos',
			path: '/marketing/videos',
		},
		{
			component: MarketingFlyers,
			title: 'Marketing Flyers',
			path: '/marketing',
		},
	],
};

const auth = {
	name: 'auth',
	routes: [
		{
			component: () => (
				<Auth subRouter={routers.authSub} setting={browserHistory.pages.auth} />
			),
			notExact: true,
			path: '/',
		},
	],
};

const authSub = {
	notFoundRedirect: '/signin',
	name: 'authSub',
	routes: [
		{
			component: Signin,
			title: 'Signin',
			path: '/signin',
		},
		{
			component: Signup,
			title: 'Signup',
			path: '/signup',
			onMount: () => {
				const { store } = browserHistory;
				if (!store.getState().meta.locations.length) {
					store.dispatch(getLocations$network());
				}
			},
		},
		{
			component: ResetPassword,
			title: 'Reset password',
			path: '/reset-password',
		},
	],
};

const routers = createRouter([main, auth, authSub, marketingSub], {
	title: 'Virtuoso | ',
});

function AppRouter() {
	const tokenIsExist = Cookies.get(cookie.TOKEN);

	return tokenIsExist ? (
		<>
			<routers.main />

			{/* Loader */}

			<Container
				mapState={(state) => ({
					loading: state.system.loading,
				})}
				render={({ loading }) =>
					loading ? <FullScreenLoader opacity={loading.opacity} /> : null
				}
			/>

			{/* Notifications */}

			<Container
				mapState={(state) => ({
					notifications: state.systemNotifications,
				})}
				render={SystemNotification}
			/>

			<Container
				mapDispatch={{
					updateSketchDocument,
					setSketchVariation,
					generateSketchPdf,
					setMatName,
					toggle,
				}}
				mapState={(state) => ({
					sketchName: state.mat.matInfo.sketchName,
					modalOpen: state.ui.active,
					sketchID:
						(state.sketch.activeSketchPreview && state.sketch.activeSketchPreview.id) ||
						null,
				})}
				render={({
					updateSketchDocument,
					setSketchVariation,
					generateSketchPdf,
					sketchName,
					modalOpen,
					sketchID,
					toggle,
				}) => (
					<>
						{/* Create new sketch variation */}

						<NewSketchRequestModal
							sketchName={sketchName}
							onSubmit={(data) => {
								setSketchVariation({ name: data, id: sketchID });
							}}
							onClose={() => toggle('variationSketchModal')}
							title="Duplicate as a new sketch"
							open={modalOpen.variationSketchModal || false}
						/>

						{/* Customer approval modal */}

						<CustomerApprovalModal
							onSubmitHandleClick={({ customerName, signature, email, id }) => {
								if (sketchID) {
									updateSketchDocument({
										customerName,
										signature,
										sketchID,
										email,
										onSuccess: () => {
											if (id === 'customer-approval-form-download-button') {
												generateSketchPdf(sketchID);
											}
										},
									});
								} else {
									toggle('customerApprovalModal');
								}
							}}
							onDownload={() => generateSketchPdf(sketchID)}
							onClose={() => toggle('customerApprovalModal')}
							open={modalOpen.customerApprovalModal || false}
						/>
					</>
				)}
			/>
		</>
	) : (
		<routers.auth />
	);
}

export default TopWrapper(AppRouter, browserHistory.history);
