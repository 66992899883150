import React from 'react';
import types from 'prop-types';
import query from 'querystringify';

// Components
import UIFiltersStruct from '../../../../components/block/structs/UIFiltersStruct';
import Container from '../../../../components/rp/Container';
import Datepicker from '../../../../components/ui/Datepicker';
import Select from '../../../../components/ui/Select';
import TriggerButton from '../../../../components/ui/TriggerButton';
// Static
import browserHistory from '../../../../static/global';

// Utils
import { formatDateService } from '../../../../utils';
import addQuery from '../../../../utils/addQuery';
// Hooks
import { useWindowWidth } from '../../../../hooks';

// Styles
import './styles.scss';

// ----------------
// Type of props

SearchFiltersForm.propTypes = {
	onSubmit: types.func.isRequired,
	toggle: types.func.isRequired,
	sizes: types.array.isRequired,
};

// Default value for props

SearchFiltersForm.defaultProps = {
	onSubmit: () => null,
	sizes: [],
};

// ----------------

export default function SearchFiltersForm(props) {
	const { getSizesNetworkProcess, onSubmit, toggle, sizes } = props;
	const { history } = browserHistory;
	const q = query.parse(history.location.search);

	// Preparation

	const currentWindowWidth = useWindowWidth();
	const isMobile = currentWindowWidth <= 1024;

	return (
		<form className="search-filters-form">
			<div className="search-filters-form__filters-block">
				<div>
					<UIFiltersStruct title="Mat Status">
						<TriggerButton
							onClick={() => {
								if (q.matStatus === '249') {
									addQuery({}, ['matStatus']);
									onSubmit();
								} else {
									addQuery({ matStatus: '249' });
									onSubmit();
								}
							}}
							active={q.matStatus === '249'}
							label="In Process"
							name="inProcess"
							id="search-filter-in-process"
						/>
						<TriggerButton
							onClick={() => {
								if (q.matStatus === '205') {
									addQuery({}, ['matStatus']);
									onSubmit();
								} else {
									addQuery({ matStatus: '205' });
									onSubmit();
								}
							}}
							active={q.matStatus === '205'}
							label="Complete Sketch"
							name="completeSketch"
							id="search-filter-complete-sketch"
						/>
					</UIFiltersStruct>
				</div>
				<div>
					<UIFiltersStruct title="Mat Type">
						<TriggerButton
							onClick={() => {
								if (q.matType === '101') {
									addQuery({}, ['matType']);
									onSubmit();
								} else {
									addQuery({ matType: '101' });
									onSubmit();
								}
							}}
							active={q.matType === '101'}
							label="Traditional"
							name="traditional"
							id="search-filter-traditional"
						/>
						<TriggerButton
							onClick={() => {
								if (q.matType === '116') {
									addQuery({}, ['matType']);
									onSubmit();
								} else {
									addQuery({ matType: '116' });
									onSubmit();
								}
							}}
							active={q.matType === '116'}
							label="Photo"
							name="photo"
							id="search-filter-photo"
						/>
						<TriggerButton
							onClick={() => {
								if (q.matType === '205') {
									addQuery({}, ['matType']);
									onSubmit();
								} else {
									addQuery({ matType: '205' });
									onSubmit();
								}
							}}
							active={q.matType === '205'}
							label="Lay Your Logo"
							name="layLogo"
							id="search-filter-lay-logo"
						/>
					</UIFiltersStruct>
				</div>
				<div>
					<UIFiltersStruct title="Orientation">
						<TriggerButton
							onClick={() => {
								if (q.orientation === 'landscape') {
									addQuery({}, ['orientation']);
									onSubmit();
								} else {
									addQuery({ orientation: 'landscape' });
									onSubmit();
								}
							}}
							active={q.orientation === 'landscape'}
							label="Landscape"
							name="landscape"
							id="search-filter-orientation-landscape"
						/>
						<TriggerButton
							onClick={() => {
								if (q.orientation === 'portrait') {
									addQuery({}, ['orientation']);
									onSubmit();
								} else {
									addQuery({ orientation: 'portrait' });
									onSubmit();
								}
							}}
							active={q.orientation === 'portrait'}
							label="Portrait"
							name="portrait"
							id="search-filter-orientation-portrait"
						/>
					</UIFiltersStruct>
				</div>
				<div className="above-all">
					<Container
						withRouter
						mapState={(state) => ({
							mobile: state.system.mobile,
						})}
						render={(cProps) => {
							const { history } = browserHistory;
							const q = query.parse(history.location.search);

							return (
								<UIFiltersStruct title="Date">
									<Datepicker
										selectsStart
										isClearable
										placeholder="Start date"
										startDate={q.startDate ? new Date(q.startDate) : null}
										selected={q.startDate ? new Date(q.startDate) : null}
										onChange={(date) => {
											if (!date) {
												addQuery({}, ['startDate']);
												onSubmit();
											} else {
												addQuery({
													startDate: formatDateService.payloadDateFormat(date),
												});
												onSubmit();
											}
										}}
										endDate={q.endDate ? new Date(q.endDate) : null}
										status={{ error: false }}
										name="startDate"
										size="xs"
									/>
									<Datepicker
										placeholderText="End date"
										isClearable
										selectsEnd
										startDate={q.startDate ? new Date(q.startDate) : null}
										selected={q.endDate ? new Date(q.endDate) : null}
										onChange={(date) => {
											if (!date) {
												addQuery({}, ['endDate']);
												onSubmit();
											} else {
												addQuery({ endDate: formatDateService.payloadDateFormat(date) });
												onSubmit();
											}
										}}
										endDate={q.endDate ? new Date(q.endDate) : null}
										minDate={q.startDate ? new Date(q.startDate) : null}
										status={{ error: false }}
										name="endDate"
										size="xs"
									/>
								</UIFiltersStruct>
							);
						}}
					/>
				</div>
				<div>
					<UIFiltersStruct title="Size">
						<Select
							selectedOption={q.size ? { value: q.size } : null}
							placeholder="Width x Length"
							loaderSize="sm"
							isLoading={getSizesNetworkProcess}
							onSelect={(size) => {
								addQuery({ size: size.value });
								onSubmit();
							}}
							onFocus={() => null}
							options={sizes}
							status={false}
							fluid
							theme="fourth"
							name="filterWidth"
							size="xs"
							keys={{ keyName: 'id', keyValue: 'value' }}
						/>
					</UIFiltersStruct>
				</div>
			</div>
			<div className="search-filters-form__reset-wrapper">
				<button
					className="text-small search-filters-form__btn search-filters-form__btn--reset"
					onClick={(e) => {
						e.preventDefault();
						Object.keys(q).forEach((item) => {
							item !== 'search' && addQuery({}, [item]);
						});
						if (isMobile) {
							toggle('searchAside');
						}
						onSubmit();
					}}
					id="search-filters-form-reset-button"
				>
					Reset Filters
				</button>
				{isMobile && (
					<button
						className="text-small search-filters-form__btn search-filters-form__btn--apply"
						onClick={(e) => {
							e.preventDefault();
							toggle('searchAside');
							onSubmit();
						}}
						id="search-filters-form-apply-button"
					>
						Apply
					</button>
				)}
			</div>
		</form>
	);
}
