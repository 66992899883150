import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Hooks

import { useWindowWidth } from '../../../hooks';

// Components

import Color from '../../../components/block/Color';

// Styles

import './styles.scss';

// ----------------

// Type of props

ColorsList.propTypes = {
	extractedColors: types.array,
	selectedColors: types.array,
	onColorClick: types.func.isRequired,
	list: types.array.isRequired,
};

// ----------------

export default function ColorsList(props) {
	const {
		enableSelectedColors,
		withPaddingBottom,
		extractedColors,
		selectedColors,
		onColorClick,
		list,
	} = props;

	// Preparation

	const currentWindowWidth = useWindowWidth();
	const isMobile = currentWindowWidth <= 576;

	// Modify styles

	const modify = classNames({
		' colors-list--withPaddingBottom': withPaddingBottom,
	});

	return (
		<div className={`colors-list${modify}`}>
			<div className="colors-list__grid">
				{list.map((color) => {
					const isSelected = selectedColors
						? selectedColors.some((colorName) => colorName === color.name)
						: false;

					const isExtracted = extractedColors
						? extractedColors.some((colorName) => colorName === color.name)
						: false;

					return (
						<div className="colors-list__cell" key={color.name}>
							<Color
								extracted={isExtracted}
								selected={isSelected}
								onClick={() =>
									 onColorClick(color.name)
								}
								pointer
								name={color.name}
								size={isMobile ? 'lg' : 'sm'}
								rgb={{ r: color.red, g: color.green, b: color.blue }}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
}
