import imageAspectRatio from 'image-aspect-ratio';
import classNames from 'classnames';
import MagicWand from 'magic-wand-tool';
import types from 'prop-types';
import React from 'react';

// Components

import TriggerButton from '../../../../components/ui/TriggerButton';
import SelectColor from '../../../../components/block/SelectColor';
import FontIcon from '../../../../components/block/FontIcon';
import Button from '../../../../components/ui/Button';
import Label from '../../../../components/block/Label';
import Modal from '../../../../components/block/Modal';

// Selectors

import getById from '../../../../redux/selectors/getById';

// Static

import {
	IMAGE_MAGIC_SPOT_THRESHOLD,
	DEFAULT_REPLACE_COLOR,
	SIMPLIFY_TOLERANT,
	SIMPLIFY_COUNT,
	IMAGE_BLUR,
} from '../../../../static/constants';

// Styles

import './styles.scss';

// ----------------

export default class MatImageFiltersModal extends React.Component {
	// Type of props

	static propTypes = {
		onClose: types.func.isRequired,
		onSave: types.func.isRequired,
		object: types.object.isRequired,
		colors: types.object.isRequired,
	};

	// -------- Constructor --------

	constructor(props) {
		super(props);

		this.state = {
			historyPosition: -1,
			selectedColors: [],
			history: [],
			color: null,
			colorOption: 'wholeImage',
			initialImage: null,
			initialWidth: 0,
			initialHeight: 0,
			currentZoom: 1,
		};

		this.canvasContext = null;
		this.threshold = 10;
		this.canvas = null;

		// Binds

		this.updateInitialImage = this.updateInitialImage.bind(this);
		this.handleColorOption = this.handleColorOption.bind(this);
		this.handleColorChange = this.handleColorChange.bind(this);
		this.getSelectedColors = this.getSelectedColors.bind(this);
		this.pushToHistory = this.pushToHistory.bind(this);
		this.replaceColor = this.replaceColor.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleZoom = this.handleZoom.bind(this);
		this.undo = this.undo.bind(this);
		this.redo = this.redo.bind(this);

		// Refs

		this.canvasRef = React.createRef();
	}

	// -------- Life cycle --------

	componentDidMount() {
		this.config();
	}

	// -------- Utils --------

	/**
	 * @name config
	 */

	config() {
		this.canvas = this.canvasRef.current;
		this.canvasContext = this.canvas.getContext('2d');

		// Load and draw image

		const {
			object: { url },
		} = this.props;

		const image = new Image();

		image.src = url;
		image.onload = () => {
			const { width, height } = imageAspectRatio.calculate(
				image.width,
				image.height,
				window.innerWidth > 576 ? 500 : 320,
				window.innerWidth > 576 ? 400 : 256
			);

			this.canvas.height = height;
			this.canvas.width = width;

			this.setState({
				initialImage: image,
				initialWidth: width,
				initialHeight: height,
			});

			this.canvasContext.drawImage(image, 0, 0, width, height);
			this.pushToHistory(
				this.canvasContext.getImageData(0, 0, this.canvas.width, this.canvas.height),
				null
			);
		};

		// Add events listeners

		this.canvas.addEventListener('click', this.handleClick);
	}

	/**
	 * @name replaceColor
	 *
	 * @param {number} x - x & y coordinates of a mouse click (relative to the canvas element)
	 * @param {number} y
	 * @param {array}  colorToReplace
	 */

	replaceColor(x, y, colorToReplace) {
		const imageData = this.canvasContext.getImageData(
			0,
			0,
			this.canvas.width,
			this.canvas.height
		);
		const { data } = imageData;
		const { color, colorOption } = this.state;

		const newColor = this.getColorObjectByName(color);

		switch (colorOption) {
			/**
			 * Whole Image option
			 */

			case 'wholeImage':
				for (let i = 0; i < data.length; i += 4) {
					const replace = this.range(colorToReplace, [data[i], data[i + 1], data[i + 2]]);

					if (replace) {
						if (this.props.mode === 'replace') {
							data[i] = newColor.red;
							data[i + 1] = newColor.green;
							data[i + 2] = newColor.blue;
						} else {
							data[i + 3] = 0;
						}
					}
				}

				this.canvasContext.putImageData(imageData, 0, 0);
				this.pushToHistory(imageData, color);
				this.updateInitialImage();

				break;

			/**
			 * Spot Selection option
			 */

			case 'spotSelection':
				const mask = this.drawMask(x, y, imageData);
				const spotSelectionColor =
					this.props.mode === 'replace' ? newColor : DEFAULT_REPLACE_COLOR;
				const traceImageData = this.trace(mask, spotSelectionColor);

				this.pushToHistory(traceImageData, color);
				this.updateInitialImage();
				break;

			default:
				break;
		}
	}

	/**
	 * @name drawMask
	 *
	 * @param {number} x - x & y coordinates of a mouse click (relative to the canvas element)
	 * @param {number} y
	 * @param {object} imageData
	 */

	drawMask(x, y, imageData) {
		let drawData = {
			data: imageData.data,
			width: imageData.width,
			height: imageData.height,
			bytes: 4,
		};

		let mask = MagicWand.floodFill(drawData, x, y, IMAGE_MAGIC_SPOT_THRESHOLD);
		mask = MagicWand.gaussBlurOnlyBorder(mask, IMAGE_BLUR);

		return mask;
	}

	/**
	 * @name trace
	 *
	 * @param {object} mask
	 * @param {array}  colorToReplace
	 */

	trace(mask, colorToReplace) {
		let cs = MagicWand.traceContours(mask);
		cs = MagicWand.simplifyContours(cs, SIMPLIFY_TOLERANT, SIMPLIFY_COUNT);

		let ctx = this.canvasContext;
		ctx.beginPath();
		for (let i = 0; i < cs.length; i++) {
			if (!cs[i].inner) continue;
			let ps = cs[i].points;
			ctx.moveTo(ps[0].x, ps[0].y);
			for (let j = 1; j < ps.length; j++) {
				ctx.lineTo(ps[j].x, ps[j].y);
			}
		}

		let color = `rgb(${colorToReplace.red}, ${colorToReplace.green}, ${colorToReplace.blue})`;
		ctx.strokeStyle = color;
		ctx.lineWidth = 1;
		ctx.stroke();

		if (this.props.mode !== 'replace') {
			ctx.globalCompositeOperation = 'destination-out';
		}

		for (let i = 0; i < cs.length; i++) {
			if (cs[i].inner) continue;
			let ps = cs[i].points;
			ctx.moveTo(ps[0].x, ps[0].y);
			for (let j = 1; j < ps.length; j++) {
				ctx.lineTo(ps[j].x, ps[j].y);
			}
		}

		ctx.fillStyle = color;
		ctx.fill();
		ctx.strokeStyle = color;
		ctx.stroke();

		const imageData = ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
		return imageData;
	}

	/**
	 * @name range
	 *
	 * @param {array} colorToReplace
	 * @param {array} testColor
	 */

	range(colorToReplace, testColor) {
		const c = this.threshold * 2.55;

		if (
			Math.abs(testColor[0] - colorToReplace[0]) < c &&
			Math.abs(testColor[1] - colorToReplace[1]) < c &&
			Math.abs(testColor[2] - colorToReplace[2]) < c
		) {
			return true;
		}

		return false;
	}

	/**
	 * @name getColorObjectByName
	 *
	 * Get color object by name
	 *
	 * @param {string} colorName
	 */

	getColorObjectByName(colorName) {
		const { colors } = this.props;

		return colors
			? getById([...colors.standart, ...colors.pms], 'name', colorName)
			: null;
	}

	/**
	 * @name pushToHistory
	 *
	 * @param {object} imageData
	 */

	pushToHistory(imageData, color) {
		this.setState((prevState) => ({
			historyPosition: prevState.historyPosition + 1,
			history: [...prevState.history.splice(0, prevState.historyPosition + 1), imageData],
			selectedColors: [
				...prevState.selectedColors.splice(0, prevState.historyPosition + 1),
				color,
			],
		}));
	}

	/**
	 * @name updateInitialImage
	 */

	updateInitialImage() {
		const image = new Image();
		image.src = this.canvas.toDataURL();
		image.onload = () => {
			this.setState({
				initialImage: image,
			});
		};
	}

	/**
	 * @name undo
	 */

	undo() {
		if (this.state.historyPosition > 0) {
			const imageData = this.state.history[this.state.historyPosition - 1];

			this.canvas.width = imageData.width;
			this.canvas.height = imageData.height;
			this.canvasContext.putImageData(imageData, 0, 0);

			this.updateInitialImage();

			this.setState((prevState) => ({
				historyPosition: prevState.historyPosition - 1,
				currentZoom: imageData.width / prevState.initialWidth,
			}));
		}
	}

	/**
	 * @name redo
	 */

	redo() {
		if (this.state.historyPosition + 1 !== this.state.history.length) {
			const imageData = this.state.history[this.state.historyPosition + 1];

			this.canvas.width = imageData.width;
			this.canvas.height = imageData.height;
			this.canvasContext.putImageData(imageData, 0, 0);

			this.updateInitialImage();

			this.setState((prevState) => ({
				historyPosition: prevState.historyPosition + 1,
				currentZoom: imageData.width / prevState.initialWidth,
			}));
		}
	}

	// -------- Handlers --------

	handleClick(e) {
		if (this.state.color || this.props.mode !== 'replace') {
			const x = e.offsetX;
			const y = e.offsetY;

			const color = this.canvasContext.getImageData(x, y, 1, 1).data;
			this.replaceColor(x, y, color);
		}
	}

	handleZoom(zoomStep) {
		const currentZoom = this.state.currentZoom + zoomStep;
		const zoomMin = 0.5;
		const zoomMax = 2.0;

		if (currentZoom >= zoomMin && currentZoom <= zoomMax) {
			this.canvas.width = this.state.initialWidth * currentZoom;
			this.canvas.height = this.state.initialHeight * currentZoom;

			this.canvasContext.drawImage(
				this.state.initialImage,
				0,
				0,
				this.canvas.width,
				this.canvas.height
			);

			this.pushToHistory(
				this.canvasContext.getImageData(0, 0, this.canvas.width, this.canvas.height),
				null
			);

			this.setState({ currentZoom });
		}
	}

	handleColorOption(option) {
		this.setState({
			colorOption: option,
		});
	}

	handleColorChange(newColor) {
		this.setState({
			color: newColor,
		});
	}

	getSelectedColors() {
		const { selectedColors, historyPosition } = this.state;
		const currentColors = selectedColors.slice(0, historyPosition + 1);
		const uniqueColors = currentColors.filter(
			(v, i, a) => a.indexOf(v) === i && v !== null
		);

		return uniqueColors;
	}

	// -------- Render --------

	render() {
		const { onClose, onSave, mode } = this.props;
		const { color } = this.state;

		const colorObject = this.getColorObjectByName(color);

		// Modify styles

		const modify = classNames({
			[` image-filters-modal__header--${mode}`]: mode === 'remove',
		});

		return (
			<Modal
				withoutPadding
				bodyAccent
				bodyHeight="full"
				maxWidth="maxFull"
				onClose={onClose}
				title={mode === 'replace' ? 'Replace color' : 'Remove color'}
				open={true}
			>
				<div className="image-filters-modal">
					{/* Header */}

					<div className={`image-filters-modal__header${modify}`}>
						{mode === 'replace' ? (
							<>
								<div className="image-filters-modal__header-color">
									<Label position="left" title="Replace with">
										<SelectColor
											enableSelectedColors
											withBorderRadius
											noDispatch
											onSelect={this.handleColorChange}
											name={color}
											size="md"
											rgb={
												colorObject
													? {
															r: colorObject.red,
															g: colorObject.green,
															b: colorObject.blue,
													  }
													: null
											}
										/>
									</Label>
								</div>
								<div className="image-filters-modal__header-buttons-wrapper">
									<div className="image-filters-modal__header-button">
										<TriggerButton
											onClick={() => {
												this.handleColorOption('wholeImage');
											}}
											active={this.state.colorOption === 'wholeImage'}
											label="Whole Image"
											name="replaceWholeImageColor"
											id="replace-whole-image-color"
										/>
									</div>
									<div className="image-filters-modal__header-button">
										<TriggerButton
											onClick={() => {
												this.handleColorOption('spotSelection');
											}}
											active={this.state.colorOption === 'spotSelection'}
											label="Spot Selection"
											name="replaceSpotSelectionColor"
											id="replace-spot-selection-color"
										/>
									</div>
								</div>
							</>
						) : (
							<div className="image-filters-modal__header-buttons-wrapper image-filters-modal__header-buttons-wrapper--remove">
								<div className="image-filters-modal__header-button">
									<TriggerButton
										onClick={() => {
											this.handleColorOption('wholeImage');
										}}
										active={this.state.colorOption === 'wholeImage'}
										label="Whole Image"
										name="removeWholeImageColor"
										id="remove-whole-image-color"
									/>
								</div>
								<div className="image-filters-modal__header-button">
									<TriggerButton
										onClick={() => {
											this.handleColorOption('spotSelection');
										}}
										active={this.state.colorOption === 'spotSelection'}
										label="Spot Selection"
										name="removeSpotSelectionColor"
										id="remove-spot-selection-color"
									/>
								</div>
							</div>
						)}
						<div className="image-filters-modal__header-actions-wrapper">
							<div
								onClick={() => this.handleZoom(0.1)}
								className="image-filters-modal__header-action"
							>
								<FontIcon icon="zoom_in" size="xl" />
							</div>
							<div
								onClick={() => this.handleZoom(-0.1)}
								className="image-filters-modal__header-action"
							>
								<FontIcon icon="zoom_out" size="xl" />
							</div>
							<div onClick={this.undo} className="image-filters-modal__header-action">
								<FontIcon icon="undo" size="xl" />
							</div>
							<div onClick={this.redo} className="image-filters-modal__header-action">
								<FontIcon icon="redo" size="xl" />
							</div>
						</div>
					</div>

					{/* Body */}

					<div className="image-filters-modal__body">
						<canvas ref={this.canvasRef} id="image-filters-canvas" />
					</div>

					{/* Buttons */}

					<div className="image-filters-modal__buttons">
						<div className="modal__two-buttons-grid image-filters-modal__buttons-grid">
							<Button
								reverseType
								onClick={onClose}
								fluid
								id="image-filters-modal-cancel-button"
							>
								Cancel
							</Button>
							<Button
								fluid
								onClick={() => {
									onSave(this.canvas.toDataURL(), this.getSelectedColors());
								}}
								id="image-filters-modal-submit-button"
							>
								Save
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}
