import { createBrowserHistory } from 'history';

/**
 * Define a global internal object.
 */

export default {
	history: createBrowserHistory(),
	store: {
		getState: () => {},
	},
	pages: {
		mat: {
			flags: {},
			MASK: 7,
			masksByTypes: {},
		},
	},
};
