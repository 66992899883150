import types from 'prop-types';
import React from 'react';

// Hooks

import { useWindowWidth, useModal } from '../../../hooks';

// Components

import {
	Search as SearchRP,
	TooltipDefaultView,
	SelectColorModal,
	SearchInput,
	ColorsList,
	Container,
	Switch,
	Color,
	Tabs,
} from '../../../components';

// Action creators

import { toggleSuggestedColors } from '../../../redux/actionCreators/mat';

// Selectors

import getObjArrayByIds from '../../../redux/selectors/getObjArrayByIds';

// Static

import { matType } from '../../../static/constants';

// Styles

import './styles.scss';

// ----------------

// Type of props

SelectColor.propTypes = {
	onSelect: types.func.isRequired,
	sketchColors: types.bool,
};

// ----------------

const tabs = {
	sketch: (props) => (
		<Container
			{...(!props.noDispatch && { mapDispatch: { onColorClick: props.onSelect } })}
			mapState={(state) => ({
				list: getObjArrayByIds(
					[...state.mat.colors.standart, ...state.mat.colors.pms],
					[state.mat.bgColor, ...state.mat.selectedColors],
					'name'
				),
			})}
			extra={{
				handleModalToggle: props.handleModalToggle,
				...(props.noDispatch && { onColorClick: props.onSelect }),
			}}
			render={(cProps) => (
				<SearchRP
					listComponentProps={{
						selectedColors: [],
						onColorClick: (data) => {
							cProps.onColorClick(data);
							cProps.handleModalToggle(null);
						},
					}}
					searchComponent={(props) => (
						<SearchInput {...props} placeholder="Search by color number" />
					)}
					listComponent={ColorsList}
					valueKey="name"
					list={cProps.list}
				/>
			)}
		/>
	),
	standard: (props) => (
		<Container
			mapDispatch={{
				toggleSuggestedColors,
				...(!props.noDispatch && { onColorClick: props.onSelect }),
			}}
			mapState={(state) => {
				const suggestedColors = getObjArrayByIds(
					state.mat.colors.standart,
					state.mat.extractedColors,
					'name'
				);
				suggestedColors.sort((a, b) => {
					if (a.name < b.name) return -1;
					return a.name > b.name ? 1 : 0;
				});

				return {
					extractedColors: state.mat.extractedColors,
					selectedColors: [...state.mat.selectedColors, state.mat.bgColor],
					toggleColors: state.mat.toggleSuggestedColors,
					matType: state.mat.matType,
					list: state.mat.toggleSuggestedColors
						? suggestedColors
						: state.mat.colors.standart,
				};
			}}
			extra={{
				handleModalToggle: props.handleModalToggle,
				...(props.noDispatch && { onColorClick: props.onSelect }),
			}}
			render={(cProps) => (
				<SearchRP
					listComponentProps={{
						enableSelectedColors: props.enableSelectedColors,
						withPaddingBottom:
							cProps.matType === matType.TRADITIONAL.name &&
							cProps.extractedColors.length,
						extractedColors: cProps.extractedColors,
						selectedColors: cProps.selectedColors,
						onColorClick: (data) => {
							cProps.onColorClick(data);
							cProps.handleModalToggle(null);
						},
					}}
					searchComponent={(props) => (
						<SearchInput {...props} placeholder="Search by color number" />
					)}
					listComponent={ColorsList}
					switchComponent={
						cProps.matType === matType.TRADITIONAL.name && cProps.extractedColors.length
							? () => (
									<div className="search-switch">
										<Switch
											onChange={() => cProps.toggleSuggestedColors()}
											active={cProps.toggleColors}
											theme="onPrimaryDark"
										/>
										<span className="search-switch__label">
											Show suggested colors only
										</span>
									</div>
							  )
							: null
					}
					valueKey="name"
					list={cProps.list}
				/>
			)}
		/>
	),
	pms: (props) => (
		<Container
			{...(!props.noDispatch && { mapDispatch: { onColorClick: props.onSelect } })}
			mapState={(state) => ({
				selectedColors: [...state.mat.selectedColors, state.mat.bgColor],
				list: state.mat.colors.pms,
			})}
			extra={{
				handleModalToggle: props.handleModalToggle,
				...(props.noDispatch && { onColorClick: props.onSelect }),
			}}
			render={(cProps) => (
				<SearchRP
					listComponentProps={{
						selectedColors: cProps.selectedColors,
						onColorClick: (data) => {
							cProps.onColorClick(data);
							cProps.handleModalToggle(null);
						},
					}}
					searchComponent={(props) => (
						<SearchInput {...props} placeholder="Search by color number" />
					)}
					listComponent={ColorsList}
					valueKey="name"
					list={cProps.list}
				/>
			)}
		/>
	),
};

// ----------------

export default function SelectColor(props) {
	const { enableSelectedColors, sketchColors, noDispatch, onSelect, ...colorProps } =
		props;

	// State

	const { modal, handleModalToggle } = useModal();

	// Tabs

	const tabLinks = [
		{ name: 'standard', label: 'Standard' },
		{ name: 'pms', label: 'PMS' },
	];

	const tabContent = [
		{
			content: tabs.standard,
			props: {
				onSelect,
				noDispatch,
				enableSelectedColors,
				handleModalToggle,
			},
			name: 'standard',
		},
		{
			content: tabs.pms,
			props: {
				onSelect,
				noDispatch,
				handleModalToggle,
			},
			name: 'pms',
		},
	];

	if (sketchColors) {
		tabLinks.unshift({ name: 'sketch', label: 'Sketch' });
		tabContent.unshift({
			content: tabs.sketch,
			props: {
				onSelect,
				handleModalToggle,
			},
			name: 'sketch',
		});
	}

	// Preparation

	const currentWindowWidth = useWindowWidth();
	const deviceSizeXS = currentWindowWidth <= 576;

	return (
		<>
			{deviceSizeXS ? (
				<>
					<div onClick={() => handleModalToggle('selectColorModal')}>
						<Color pointer {...colorProps} />
					</div>
					<SelectColorModal
						tabsComponent={() => <Tabs tabLinks={tabLinks} tabContent={tabContent} />}
						onClose={() => handleModalToggle(null)}
						open={modal === 'selectColorModal'}
					/>
				</>
			) : (
				<TooltipDefaultView
					maxWidth="351px"
					render={<Tabs tabLinks={tabLinks} tabContent={tabContent} />}
					title="Select color"
				>
					<Color pointer {...colorProps} />
				</TooltipDefaultView>
			)}
		</>
	);
}
