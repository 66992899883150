import types from 'prop-types';
import React from 'react';

// Hooks

import { useWindowWidth } from '../../../../hooks';

// Components

import { TriggerButton, SizeTooltip, ToolSlider } from '../../../../components';

// Styles

import './styles.scss';
import { setCustomSize } from '../../../../redux/actionCreators/mat';

// ----------------

// Type of props

SizeTool.propTypes = {
	onLandscapeClick: types.func.isRequired,
	onPortraitClick: types.func.isRequired,
	matOrientation: types.string.isRequired,
	onSizeClick: types.func.isRequired,
	onAddCustomSizeClick: types.func.isRequired,
	selectedId: types.number.isRequired,
	list: types.array.isRequired,
};

// ----------------

export default function SizeTool(props) {
	const {
		onAddCustomSizeClick,
		onLandscapeClick,
		onPortraitClick,
		matOrientation,
		onSizeClick,
		selectedId,
		list,
	} = props;
	// Preparation

	const currentWindowWidth = useWindowWidth();
	const deviceSizeXS = currentWindowWidth <= 576;

	// Handlers

	function getInitialSlide() {
		const sizeToolWidth = window.innerWidth - 40;
		const slideWidth = 67;

		const lastIndexInView = Math.trunc(sizeToolWidth / slideWidth) - 1;
		const selectedIndex = list.findIndex((item) => item.id === selectedId);
		const lastIndex = list.length - 1;

		// Keep slider in a position with the selected option

		if (selectedIndex > lastIndexInView) {
			if (selectedIndex < lastIndex - selectedIndex) {
				return selectedIndex - 2;
			}

			if (selectedIndex > lastIndex - selectedIndex) {
				return lastIndex - 2;
			}
		}

		return 0;
	}

	return (
		<div className="size-tool">
			{/* Size */}

			{deviceSizeXS ? (
				<div className="size-tool__slider">
					<ToolSlider list={list} initialSlide={getInitialSlide()}>
						{list.map((item) => (
							<div className="size-tool__slide" key={item.id}>
								<TriggerButton
									label={item.value}
									size="md"
									{...(selectedId === item.id
										? { active: true }
										: {
												onClick: () => {
													onSizeClick(item.id);
												},
										  })}
								/>
							</div>
						))}
					</ToolSlider>
				</div>
			) : (
				<div className="size-tool__grid size-tool__grid--size">
					{list.slice(0, 4).map((item) => (
						<div className="size-tool__cell" key={item.id}>
							<TriggerButton
								label={item.value}
								{...(selectedId === item.id
									? { active: true }
									: {
											onClick: () => {
												onSizeClick(item.id);
											},
									  })}
							/>
						</div>
					))}

					<SizeTooltip
						list={list}
						selectedId={selectedId}
						onAddCustomSizeClick={onAddCustomSizeClick}
						onSizeClick={onSizeClick}
					>
						<div className="size-tool__cell">
							<TriggerButton label="More" onClick={() => {}} />
						</div>
					</SizeTooltip>
				</div>
			)}

			{/* Orientation */}

			<div className="size-tool__grid size-tool__grid--orientation">
				<div className="size-tool__cell">
					<TriggerButton
						label="Portrait"
						size={deviceSizeXS ? 'md' : 'sm'}
						{...(deviceSizeXS ? { borderRadius: 'left' } : {})}
						{...(matOrientation === 'Portrait'
							? { active: true }
							: {
									onClick: onPortraitClick,
							  })}
					/>
				</div>
				<div className="size-tool__cell">
					<TriggerButton
						label="Landscape"
						size={deviceSizeXS ? 'md' : 'sm'}
						{...(deviceSizeXS ? { borderRadius: 'right' } : {})}
						{...(matOrientation === 'Landscape'
							? { active: true }
							: {
									onClick: onLandscapeClick,
							  })}
					/>
				</div>
			</div>
		</div>
	);
}
