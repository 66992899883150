import React from 'react';
import query from 'querystringify';

// Components
import {
	MatsPagesStruct,
	MatPreviewModal,
	AttentionModal,
	MatPreview,
	MainHeader,
	MainStruct,
	Container,
} from '../../components';
import { simpleDeleteDraft } from '../../redux/actionCreators/mat';
import {
	showLoadMoreButton as showLoadMoreButtonAction,
	getDrafts$network,
	getMoreDrafts,
} from '../../redux/actionCreators/mats';
// Action creators
import { setActiveSketchPreview } from '../../redux/actionCreators/sketch';
import { toggle } from '../../redux/actionCreators/ui';
// Selectors
import getById from '../../redux/selectors/getById';
// Action types
import { GET_MORE_DRAFTS_$NETWORK, GET_DRAFTS_$NETWORK } from '../../redux/types/mats';
// Static
import browserHistory from '../../static/global';

// Utils
import { getMatStatus } from '../../utils';
import addQuery from '../../utils/addQuery';

// ----------------

export default function MyDrafts(props) {
	return (
		<>
			<MainStruct
				header={
					<Container
						mapDispatch={{ toggle }}
						mapState={(state) => ({
							favouriteQuantity: state.mats.favouriteSketchesQty,
							sidebarOpen: state.ui.active.sidebar,
						})}
						render={MainHeader}
					/>
				}
			>
				<Container
					mapDispatch={{
						showLoadMoreButtonAction,
						setActiveSketchPreview,
						getDrafts$network,
						getMoreDrafts,
						toggle,
					}}
					mapState={(state) => ({
						getMoreNetworkProcess: state.network[GET_MORE_DRAFTS_$NETWORK],
						showLoadMoreButton: state.mats.showLoadMoreButton,
						getNetworkProcess: state.network[GET_DRAFTS_$NETWORK],
						myDrafts: state.mats.drafts,
					})}
					render={(cProps) => {
						const { history } = browserHistory;
						const q = query.parse(history.location.search);

						return (
							<MatsPagesStruct
								loadMoreNetworkProcess={cProps.getMoreNetworkProcess}
								getNetworkProcess={cProps.getNetworkProcess}
								headerSortActions={[
									{
										onClick: async () => {
											if (q.sortDir !== 'desc') {
												addQuery({ sortDir: 'desc' });
												addQuery({}, ['page']);

												await cProps.getDrafts$network({
													recordCount: 12,
													pageNumber: 0,
													sortExpr: 'date',
													sortDir: 'desc',
												});
												cProps.showLoadMoreButtonAction(true);
											}
										},
										active: q.sortDir ? q.sortDir === 'desc' : true,
										label: 'Date Decrease',
										icon: 'expand_more',
										id: 'sketches-date-decrease',
									},
									{
										onClick: async () => {
											if (q.sortDir !== 'asc') {
												addQuery({ sortDir: 'asc' });
												addQuery({}, ['page']);

												await cProps.getDrafts$network({
													recordCount: 12,
													pageNumber: 0,
													sortExpr: 'date',
													sortDir: 'asc',
												});
												cProps.showLoadMoreButtonAction(true);
											}
										},
										active: q.sortDir === 'asc',
										label: 'Date Increase',
										icon: 'expand_less',
										id: 'sketches-date-increase',
									},
								]}
								showLoadMore={cProps.showLoadMoreButton}
								onLoadMore={() => {
									addQuery({ page: q.page ? +q.page + 1 : 2 });
									cProps.getMoreDrafts();
								}}
								titleIcon="burst_mode"
								title="My Drafts"
								mats={cProps.myDrafts}
								card={({
									productGroupID,
									attributeName,
									companyName,
									designName,
									sketchRef,
									sketchURL,
									scanURL,
									imageName,
									viewMode,
									draftId,
									created,
									...cardProps
								}) => (
									<MatPreview
										withHoverEffect
										companyName={companyName}
										imageName={imageName || undefined}
										favourite={0}
										maxWidth="auto"
										viewMode={viewMode}
										onClick={() => {
											cProps.setActiveSketchPreview(draftId, 'draft', 'drafts');
											cProps.toggle('matPreviewModal');
										}}
										sketchRef={sketchRef}
										status={getMatStatus(cardProps)}
										image={sketchURL || scanURL}
										size={attributeName}
										name={designName}
										date={created}
										type={productGroupID || undefined}
										id={draftId}
									/>
								)}
							/>
						);
					}}
				/>
			</MainStruct>

			<Container
				mapDispatch={{ simpleDeleteDraft, toggle }}
				mapState={(state) => ({
					attentionModalOpen: state.ui.active.attentionModal,
					matDetails:
						(state.sketch.activeSketchPreview &&
							getById(
								state.mats.drafts,
								'draftId',
								state.sketch.activeSketchPreview.id
							)) ||
						{},
					modalOpen: state.ui.active.matPreviewModal,
				})}
				render={({
					matDetails: {
						productGroupID,
						attributeName,
						companyName,
						designName,
						imageName,
						sketchRef,
						sketchURL,
						scanURL,
						created,
						draftId,
						...matProps
					},
					...cProps
				}) => (
					<>
						<MatPreviewModal
							onDelete={() => {
								cProps.toggle('matPreviewModal');
								cProps.toggle('attentionModal');
							}}
							onClose={() => cProps.toggle('matPreviewModal')}
							onEdit={() => {
								browserHistory.history.push(`/draft/${draftId}`);
								localStorage.setItem('draftStatus', 'edit');
								cProps.toggle('matPreviewModal');
							}}
							open={cProps.modalOpen || false}
							data={{
								withFavouriteIcon: false,
								companyName: companyName,
								imageName: imageName,
								sketchRef: sketchRef,
								favourite: 0,
								status: getMatStatus(matProps),
								image: sketchURL || scanURL,
								type: productGroupID || undefined,
								size: attributeName,
								name: designName,
								date: created,
								id: draftId,
							}}
						/>

						<AttentionModal
							secondaryButtonText="Cancel"
							primaryButtonText="Delete"
							onSecondaryClick={() => cProps.toggle('attentionModal')}
							onPrimaryClick={() => {
								cProps.simpleDeleteDraft(draftId);
								cProps.toggle('attentionModal');
							}}
							subtitle="Are you sure you want to delete your draft?"
							onClose={() => cProps.toggle('attentionModal')}
							open={cProps.attentionModalOpen || false}
						/>
					</>
				)}
			/>
		</>
	);
}
