import imageFromUrl2base64 from 'image-to-base64';

import { saveDraft$network, getSizes, setSizeValue } from './mat';
import { startLoading, endLoading } from './system';
import { successMsgs, errorMsgs } from './systemNotifications';
import notification from '../../static/data/notification';
import endpoints from '../../static/endpoints/sketch';
import browserHistory from '../../static/global';
import { draft } from '../../static/payloads/mats';
import getById from '../selectors/getById';
import * as types from '../types/sketch';

import { getCustomSketchData } from '../../utils';

// -------- Classic action creator --------
// setActiveSketchPreview

export const setActiveSketchPreview = (id, status, list) => ({
	type: types.SET_ACTIVE_SKETCH_PREVIEW,
	payload: { id: id, status: status, list: list },
});

// setSketchDataById

export const setSketchDataById = (payload) => ({
	type: types.SET_SKETCH_DATA_BY_ID,
	payload,
});

// Restore branch

export const restoreBranch = (type) => ({
	type: types.RESTORE_BRANCH,
});

// -------- Async action creator --------

// saveSketchAsDraft

export const saveSketchAsDraft =
	({
		selectedSizeId,
		matOrientation,
		selectedColors,
		logoMatType,
		sketchImage,
		bgColor,
		name,
		id,
	}) =>
	async (store) => {
		const { dispatch, getState } = store;
		const { user } = getState();
		let res;
		const jsonData = JSON.stringify({
			selectedSizeId,
			matOrientation,
			selectedColors: selectedColors.map((item) => item.name),
			objects: {},
			matInfo: { sketchName: name, variationOf: id },
			bgColor: bgColor,
			bgImage: sketchImage,
			nextId: 2,
		});

		const sketchBase64Image = await imageFromUrl2base64(sketchImage);

		const body = {
			...draft,
			sketchImage: sketchBase64Image,
			logoMatType: logoMatType,
			placedbyId: user.userData.UserID,
			locationId: user.userData.CustomerID || 6110,
			jsonData: jsonData,
			realName: name,
		};

		res = await dispatch(saveDraft$network(body));

		return res;
	};

// setSketchVariation

export const setSketchVariation = (payload) => async (store) => {
	const { dispatch, getState } = store;

	dispatch(startLoading(false));
	if (!getState().mat.matSizes.length) {
		await dispatch(getSizes());
	}

	const sketchData = await dispatch(getSketchDataById$network(payload.id));

	const { colorListRgb, orientation, matTypeID, image, bgRgb, size } =
		getCustomSketchData(sketchData.result.data.sketchRequestList[0]);

	// console.log(sketchAttributes, 'sketchAttributes');

	const selectedSizeId = getById(getState().mat.matSizes, 'value', size).id;

	dispatch(setSizeValue({ matSize: size }));

	if (!payload.notSave) {
		const res = await dispatch(
			saveSketchAsDraft({
				selectedSizeId,
				matOrientation: orientation,
				selectedColors: colorListRgb,
				logoMatType: matTypeID,
				sketchImage: image,
				bgColor: bgRgb ? bgRgb.name : undefined,
				name: payload.name,
				id: payload.id,
			})
		);
		dispatch(getSizes(size));

		if (res.result.data) {
			browserHistory.history.push(`/draft/${res.result.data.draftId}`);
		} else {
			dispatch(endLoading());

			dispatch(
				errorMsgs({
					message: notification.saveDraftError,
				})
			);
		}
	} else {
		dispatch(getSizes(size));
	}
};

// updateSketchDocumentForVirtuoso$network

export const updateSketchDocument =
	({ customerName, signature, sketchID, email, onSuccess }) =>
	async (store) => {
		const { dispatch } = store;
		const preparedPayload = {
			SketchRequest: {
				SketchRequestID: sketchID,
				CustomerName: customerName,
				EmailCC: email,
			},
			SignatureImage: signature,
		};

		dispatch(startLoading());

		const res = await dispatch(updateSketchDocument$network(preparedPayload));

		if (res.result.data === sketchID && !res.result.isError) {
			dispatch(endLoading());

			setTimeout(() => {
				dispatch(
					successMsgs({
						message: notification.updateSketchDocumentSuccess,
					})
				);
				onSuccess();
			}, 500);
		} else {
			dispatch(endLoading());

			setTimeout(() => {
				dispatch(
					errorMsgs({
						message: notification.updateSketchDocumentError,
					})
				);
			}, 500);
		}
	};

// -------- Async network action creator --------

// getSketchDataById$network

export const getSketchDataById$network = (id) => ({
	type: types.GET_SKETCH_DATA_BY_ID_$NETWORK,
	endpoint: `${endpoints.GET_SKETCH_DATA_BY_ID}/${id}`,
});

// updateSketchDocumentForVirtuoso$network

export const updateSketchDocument$network = (payload) => ({
	type: types.UPDATE_SKETCH_DOCUMENT_$NETWORK,
	endpoint: endpoints.UPDATE_SKETCH_DOCUMENT,
	payload,
	method: 'POST',
});
