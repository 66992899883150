import * as types from '../types/meta';

// Static

import endpoints from '../../static/endpoints/meta';

// -------- Async network action creator --------

// getLocations$network

export const getLocations$network = () => ({
	type: types.GET_LOCATIONS_$NETWORK,
	endpoint: endpoints.GET_LOCATIONS,
	payload: {
		description: '',
		url:
			process.env.REACT_APP_LOCATIONS_REQUEST_URL ||
			'https://www.cintasmats.com',
	},
	method: 'POST',
});
