import types from 'prop-types';
import React from 'react';

// Components

import InputField from '../../../../components/ui/InputField';

// Styles

import './styles.scss';

// ----------------

// Type of props

ObjectParamsForm.propTypes = {
	fields: types.object.isRequired,
};

// Default value for props
ObjectParamsForm.defaultProps = {
	onSubmit: () => null,
};

// ----------------

export default function ObjectParamsForm(props) {
	const { fields, onSubmit } = props;

	return (
		<form className="object-params-form" onSubmit={onSubmit}>
			<div className="object-params-form__field object-params-form__field--width">
				<InputField
					withPadding={false}
					titleSize="small"
					title="Width"
					theme="onFourth"
					size="xs"
					{...fields.width}
				/>
			</div>
			<div className="object-params-form__field object-params-form__field--height">
				<InputField
					withPadding={false}
					titleSize="small"
					title="Height"
					theme="onFourth"
					size="xs"
					{...fields.height}
				/>
			</div>
			<div className="object-params-form__field object-params-form__field--left">
				<InputField
					withPadding={false}
					titleSize="small"
					title="Left"
					theme="onFourth"
					size="xs"
					{...fields.left}
				/>
			</div>
			<div className="object-params-form__field object-params-form__field--top">
				<InputField
					withPadding={false}
					titleSize="small"
					title="Top"
					theme="onFourth"
					size="xs"
					{...fields.top}
				/>
			</div>
			<div className="object-params-form__field object-params-form__field--angle">
				<InputField
					withPadding={false}
					titleSize="small"
					title="Angle"
					theme="onFourth"
					size="xs"
					{...fields.angle}
				/>
			</div>
			<button type="submit" style={{ display: 'none' }} />
		</form>
	);
}
